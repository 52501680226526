import {
  Alert,
  Button,
  Card,
  Col,
  Comment,
  Divider,
  Input,
  Layout,
  Modal,
  Row,
  Tag,
  Tooltip,
  Upload,
  message,
  notification,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  feedbackVideoRequest,
  getVideoRequest,
  postVideoRequest,
  validateVideoRequest,
} from "../services";
import { useEffect, useState } from "react";
import { IVideoRequest, SupportedLanguage, VideoRequestStatus } from "../model";
import {
  ArrowLeftOutlined,
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  LoadingOutlined,
  SaveOutlined,
  SendOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import ApiVideoPlayer from "@api.video/react-player";
import moment from "moment";
import styled from "styled-components";
import { languageOptions, secondsToMMSS } from "~/utils/helpers";
import LoadingLayout from "./shared/LoadingLayout";
import { Flex } from "./shared/global";
import { AxiosError } from "axios";

const VideoRequestReview = () => {
  const { id: requestId, language: videoLanguage } = useParams();
  const navigate = useNavigate();
  const [videoRequest, setVideoRequest] = useState<IVideoRequest | null>(null);
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [currentComment, setCurrentComment] = useState<string>("");
  const [videoTimer, setVideoTimer] = useState<number>(0);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    fetchRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId]);

  const fetchRequest = async () => {
    if (!requestId) return;
    const req = await getVideoRequest(requestId);

    setVideoRequest(req);
  };

  const handleSaveReview = async () => {
    if (!requestId || !videoRequest || !video) {
      navigate(-1);
      return console.error("Missing data");
    }

    setIsSaving(true);
    try {
      if (!videoFile) throw Error("No video file to upload");
      const newVideoRequest = await postVideoRequest(requestId, videoFile);
      setVideoRequest({
        ...videoRequest,
        videos: newVideoRequest.videos,
      });

      message.success("Video uploaded successfully, review saved!");
    } catch (error) {
      notification.error({
        message: "An error occured",
        description:
          (error as AxiosError<{ message: string }>)?.response?.data?.message ||
          "An error occured while saving the review",
      });
      return error;
    }
    setIsSaving(false);
  };

  const handleSendFeedback = async () => {
    const feedback = {
      message: currentComment || "",
      timer: secondsToMMSS(videoTimer),
      language: videoLanguage as SupportedLanguage,
      requestId: requestId || "",
    };

    const newRequest = await feedbackVideoRequest(feedback);
    notification.success({
      message: "Feedback sent",
    });

    setVideoRequest(newRequest);
    setCurrentComment("");
  };

  const handleValidateRequest = async () => {
    if (!requestId || !videoRequest) return;

    Modal.confirm({
      title: "Validate request",
      content: "Are you sure you want to validate this request?",
      onOk: async () => {
        try {
          const res = await validateVideoRequest(requestId);
          notification.success({
            message: "Request validated",
            description: "The request has been validated successfully",
          });
          setVideoRequest({
            ...videoRequest,
            status: res.status,
          });
        } catch (error) {
          notification.error({
            message: "An error occured",
            description:
              (error as AxiosError<{ message: string }>)?.response?.data
                ?.message || "An error occured while validating the request",
          });
          return error;
        }
      },
    });
  };

  const video = videoRequest?.videos[0];

  if (!videoRequest || !video) return <LoadingLayout />;

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Layout.Content
        style={{
          padding: "20px 50px 200px",
        }}
      >
        <Button
          type='link'
          style={{ padding: 0, marginBottom: 16 }}
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <Flex align='center' justify='space-between'>
          <h1
            style={{
              fontWeight: 800,
              fontSize: "30px",
              margin: 0,
              width: "100%",
            }}
            onClick={() => console.log("videoRequest:", videoRequest)}
          >
            {videoRequest.name}
          </h1>

          {videoRequest?.status === VideoRequestStatus.WAITING ? (
            <Flex align='center' gap={8} justify='end'>
              {videoRequest?.videos[0]?.videoContent ? (
                <Button
                  style={{
                    background: "rgb(22, 163, 74)",
                    borderColor: "rgb(22, 163, 74)",
                    color: "white",
                  }}
                  icon={<CheckOutlined />}
                  onClick={handleValidateRequest}
                >
                  Validate
                </Button>
              ) : null}
              <Button danger type='primary' icon={<CloseOutlined />}>
                {"Decline"}
              </Button>
              <Divider type='vertical' />
              <Button
                style={{ marginRight: 10 }}
                onClick={handleSaveReview}
                type={"primary"}
                icon={<SaveOutlined />}
                loading={isSaving}
              >
                {"Save"}
              </Button>
            </Flex>
          ) : videoRequest?.status === VideoRequestStatus.WAITING_VALIDATION ? (
            <Tag icon={<LoadingOutlined spin />} color='orange'>
              {"Waiting for validation"}
            </Tag>
          ) : (
            <Tag color='red'>{`Declined (${videoRequest?.status})`}</Tag>
          )}
        </Flex>
        <Divider />
        <Row gutter={24}>
          <Col span={14}>
            {video.videoId ? (
              <ApiVideoPlayer
                video={{
                  id: video.videoId,
                }}
                style={{
                  width: "100%",
                  height: 500,
                }}
                onTimeUpdate={(timer) => setVideoTimer(timer)}
              />
            ) : (
              <Alert
                message='No video available'
                description='Please upload a video to review this request'
              />
            )}

            <Divider />

            <StyledUpload
              accept='.mp4'
              customRequest={(payload) => {
                if (payload.onSuccess) payload.onSuccess("ok");
              }}
              onChange={({ file }) => {
                if (file.status === "done") {
                  setVideoFile(file.originFileObj as File);
                } else if (file.status === "error") {
                  message.error(`${file.name} file upload failed.`);
                }
              }}
            >
              <Button
                style={{ width: "100%" }}
                htmlType='button'
                icon={<UploadOutlined />}
                disabled={videoRequest?.status !== VideoRequestStatus.WAITING}
              >
                Upload Video
              </Button>
            </StyledUpload>

            {/* TODO: remove 'add comment' section */}
            <div>
              <Divider />
              <Input.TextArea
                placeholder='Add a comment'
                onChange={(e) => setCurrentComment(e.target.value)}
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
              <Button
                icon={<SendOutlined />}
                type='primary'
                style={{ marginTop: 10, float: "right" }}
                onClick={handleSendFeedback}
              >
                Send comment
              </Button>
            </div>
          </Col>
          <Col span={10}>
            <Card title={video.title}>
              <p>
                <b>{"Language: "}</b>
                {
                  languageOptions.find((lng) => lng.value === video.language)
                    ?.label
                }
              </p>
              <p>
                <b>Label:</b> {videoRequest.name}
              </p>
              <Button
                icon={<DownloadOutlined />}
                type='link'
                style={{ padding: 0 }}
                href={videoRequest.pdf.url}
                target='_blank'
              >
                {"Download PDF"}
              </Button>
            </Card>
            <Divider />
            <Card title={"Comments"}>
              {video.feedbacks?.length
                ? [...video.feedbacks]?.reverse().map((feedback, index) => (
                    <div
                      key={feedback._id}
                      style={{
                        opacity: feedback.status === "Done" ? 0.5 : 1,
                      }}
                    >
                      <Comment
                        author={
                          <div>
                            <Tag color='volcano'>
                              {feedback.timer || "00:00"}
                            </Tag>
                            <Tooltip
                              title={moment(feedback.createdAt).format(
                                "YYYY/MM/DD [at] HH:mm"
                              )}
                            >
                              <span>
                                {moment(feedback.createdAt).fromNow()}
                              </span>
                            </Tooltip>
                          </div>
                        }
                        content={
                          <div>
                            <p style={{ margin: "8px 0" }}>
                              {feedback.message}
                            </p>
                            <small>
                              {feedback.status === "Done"
                                ? "✅ Done"
                                : "⏳ In progress"}
                            </small>
                          </div>
                        }
                      />
                      {index !== video.feedbacks.length - 1 && <Divider />}
                    </div>
                  ))
                : "No comments yet"}
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default VideoRequestReview;

const StyledUpload = styled(Upload)`
  div.ant-upload {
    width: 100%;
  }
`;
