import { useEffect, useState } from "react";
import { Button, Col, Divider, Layout, Row, Select, Table, Tag } from "antd";
import { IVideoRequest, VideoRequestStatus } from "../model";
import { getVideoRequestList } from "../services";
import moment from "moment";
import {
  ArrowLeftOutlined,
  BarChartOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  UnorderedListOutlined,
  VideoCameraAddOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { languageOptions } from "~/utils/helpers";

const VideoRequestList = () => {
  const navigate = useNavigate();
  const [requestsList, setRequestsList] = useState<Array<IVideoRequest> | null>(
    null
  );

  const [fetchStatus, setFetchStatus] = useState<VideoRequestStatus[] | null>(
    null
  );

  const getRequestList = async () => {
    const { docs: requests } = await getVideoRequestList(
      !fetchStatus?.length || !fetchStatus ? undefined : fetchStatus
    );
    setRequestsList(requests);
  };

  useEffect(() => {
    getRequestList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStatus]);

  const columns: ColumnsType<IVideoRequest> = [
    {
      title: "Request name",
      dataIndex: "name",
      key: "name",
      width: 400,
      render: (text, record) => (
        <Link to={`/content-management/video/requests/${record._id}`}>
          {text}
        </Link>
      ),
    },
    {
      title: "Organisation",
      dataIndex: ["organisation", "name"],
      key: "organisation",
      width: 200,
    },
    {
      title: "Language",
      key: "language",
      width: 100,
      // TODO: update with single-video
      render: (_, record) =>
        record.videos?.[0]?.language
          ? languageOptions.find(
              (lang) => lang.value === record.videos?.[0]?.language
            )?.label
          : "N/A",
    },
    {
      title: "Creation Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (text) => moment(new Date(text)).format("MM/DD/YYYY"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (text) => (
        <Tag
          icon={
            text === VideoRequestStatus.DONE ? (
              <CheckOutlined />
            ) : text === VideoRequestStatus.WAITING ||
              text === VideoRequestStatus.WAITING_VALIDATION ? (
              <SyncOutlined spin />
            ) : (
              <CloseCircleOutlined />
            )
          }
          color={
            text === VideoRequestStatus.WAITING ||
            text === VideoRequestStatus.WAITING_VALIDATION
              ? "orange"
              : text === VideoRequestStatus.REJECTED
              ? "red"
              : text === VideoRequestStatus.CANCELED
              ? "purple"
              : "green"
          }
        >
          {text}
        </Tag>
      ),
    },
    {
      title: "PDF File",
      dataIndex: "pdf",
      key: "pdf",
      render: (_, record) => (
        <a href={record.pdf.url} target='_blank' download={record._id + ".pdf"}>
          Download PDF
        </a>
      ),
    },
  ];

  return (
    <Layout
      style={{
        overflow: "hidden",
        boxSizing: "border-box",
        height: "100vh",
      }}
    >
      <Layout.Content
        style={{
          padding: "20px 50px 50px",
          boxSizing: "border-box",
        }}
      >
        <Row
          justify='space-between'
          align='middle'
          style={{ margin: "20px 0" }}
        >
          <Col>
            <h1 style={{ fontWeight: 800, fontSize: "30px", margin: 0 }}>
              Video Requests
            </h1>
          </Col>
          <Col>
            <Select
              placeholder='Filter by status'
              style={{ width: 300 }}
              mode='multiple'
              onChange={(status) => setFetchStatus(status)}
              options={Object.values(VideoRequestStatus).map((status) => ({
                label: status,
                value: status,
              }))}
            />
            <Divider type='vertical' />
            <Button
              icon={<VideoCameraAddOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => navigate("/content-management/video/requests")}
              type={"primary"}
            >
              Requests
            </Button>
            <Button
              icon={<UnorderedListOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => navigate("/content-management/video")}
              type={"default"}
            >
              Management
            </Button>
            <Button
              icon={<BarChartOutlined />}
              style={{ marginRight: 10 }}
              onClick={() => navigate("/content-management/video?metrics=true")}
              type={"default"}
            >
              Metrics
            </Button>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={requestsList?.map((request) => ({
            ...request,
            key: request._id,
          }))}
          scroll={{ x: "max-content", y: "calc(100vh - 300px)" }}
          footer={() => (
            <Button
              icon={<ArrowLeftOutlined />}
              type='link'
              onClick={() => navigate(-1)}
            >
              {"Back to videos"}
            </Button>
          )}
        />
      </Layout.Content>
    </Layout>
  );
};

export default VideoRequestList;
