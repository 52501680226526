import { useContext, useEffect, useState } from "react";
import { Button, Card, Table, Tag, notification } from "antd";
import { Flex, Spacer } from "../../components/shared/global";
import { getNotificationsList } from "~/services";
import { INotification, SupportedLanguage } from "~/model";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { Link } from "react-router-dom";
import moment from "moment";
import { AppstoreAddOutlined, LoadingOutlined } from "@ant-design/icons";
import { SorterResult } from "antd/lib/table/interface";
import { GlobalContext } from "~/context/global.context";

const NotificationList = () => {
  const { roomList } = useContext(GlobalContext);
  const [notifications, setNotifications] = useState<{
    docs: INotification[];
    meta: { total: number; offset: number; limit: number };
  } | null>(null);

  const handleFetchList = async (
    offset = 0,
    limit = 20,
    sortOrder: "asc" | "desc" = "desc"
  ) => {
    try {
      const res = await getNotificationsList({
        limit,
        offset,
        sortOrder,
      });
      setNotifications(res);
    } catch (error) {
      notification.error({
        message: "Error",
        description: "An error occurred while fetching the notification",
      });
      throw error;
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _filters: unknown,
    sorter: SorterResult<INotification> | SorterResult<INotification>[]
  ) => {
    const _sorter = sorter as SorterResult<INotification>;
    const sortOrder = _sorter.order === "ascend" ? "asc" : "desc";

    handleFetchList(
      (pagination.current || 1) - 1,
      pagination.pageSize || 20,
      sortOrder
    );
  };

  useEffect(() => {
    handleFetchList();
  }, []);

  const columns: ColumnsType<INotification> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (title) => <span style={{ fontWeight: 600 }}>{title}</span>,
    },
    {
      title: "Read",
      dataIndex: "read",
      key: "read",
      render: (read) =>
        read ? <Tag color='green'>{"👍 Yes"}</Tag> : <Tag>{"👎 No"}</Tag>,
    },
    {
      title: "Type",
      dataIndex: ["action", "notificationType"],
      key: "notificationType",
      render: (notificationType, notif: INotification) =>
        notificationType === "ExternalLink" ? (
          <Link to={notif.action.url}>{notificationType}</Link>
        ) : (
          notificationType
        ),
    },
    {
      title: "Room",
      dataIndex: "room",
      key: "room",
      render: (room) =>
        roomList ? (
          roomList.find((r) => r.id === room)?.name || "N/A"
        ) : (
          <LoadingOutlined />
        ),
    },
    {
      title: "Content",
      key: "body",
      render: (_, notif) => {
        if (notif.body) return notif.body || "N/A";
        else if (notif?.contents)
          return (
            <div
              style={{
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
                wordWrap: "break-word",
              }}
            >
              {Object.keys(notif?.contents || {})
                .map(
                  (lng) =>
                    `${
                      notif?.contents?.[lng as SupportedLanguage].text
                    } (${lng.toUpperCase()})`
                )
                .join("\n") || "N/A"}
            </div>
          );

        return "N/A";
      },
    },
    {
      title: "Creation date",
      dataIndex: ["meta", "creationDate"],
      key: "title",
      width: 140,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      render: (creationDate) => moment(creationDate).format("MM/DD/YYYY"),
    },
  ];

  return (
    <div className='basic-layout'>
      <Flex justify='space-between'>
        <h1 style={{ fontWeight: 800, fontSize: "30px" }}>Push Notification</h1>
        <Link to={"create"}>
          <Button icon={<AppstoreAddOutlined />} type='primary'>
            Create notification
          </Button>
        </Link>
      </Flex>
      <Spacer />

      <Card title='Notifications list'>
        <Table
          size='small'
          loading={!notifications}
          columns={columns}
          dataSource={notifications?.docs.map((notif) => ({
            key: notif.id,
            ...notif,
          }))}
          onChange={handleTableChange}
          pagination={{
            total: notifications?.meta.total || 0,
            pageSize: notifications?.meta.limit || 20,
            current: (notifications?.meta.offset || 0) + 1,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
          }}
        />
      </Card>
    </div>
  );
};

export default NotificationList;
